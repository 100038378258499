* {
  box-sizing: border-box;
}

.navbar {
  overflow: hidden;
  background-color: #132043;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 2px 8px 0px #4e5683;
  padding: 0 2rem;
  border-radius: 0.5rem;
}



.navbar-links {
  display: flex;
  flex: 2;
  justify-content: center;
}

.navbar a {
  float: left;
  font-size: 16px;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

.navbar a.icon {
  display: none;
}

.dropdown {
  float: left;
  overflow: hidden;
}

.dropdown .dropbtn {
  font-size: 16px;
  border: none;
  outline: none;
  color: white;
  padding: 14px 16px;
  background-color: inherit;
  font: inherit;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.navbar a:hover,
.dropdown:hover .dropbtn {
  color: #ffc501;
}

#getQuoteButton:hover {
  color: #132043;
}

.navbar > a > img {
  height: 3rem;
  padding: 0;
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
  object-fit: contain;
}

.logo-container {
  display: flex;
  align-items: center;
  padding: 0;
}

.logo-image {
  height: 3rem;
  margin: -0.25rem 1rem -0.25rem 0;
  padding: 0;
}

.button {
  color: #132043;
  background-color: #ffc501;
  display: flex;
  width: 10rem;
  height: 3rem;
  padding: 0.625rem 1.25rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  flex-shrink: 0;
  border-radius: 0.625rem;
  font-weight: bold;
  font-size: 1rem;
}

@media screen and (max-width: 890px) {
  .navbar-links {
    flex-direction: column;
  }
  .navbar a:not(:first-child),
  .dropdown .dropbtn {
    display: none;
  }
  .button{
    margin-bottom: 10px;
  }
  .navbar a.icon {
    float: right;
    display: block;
  }
  .navbar.responsive {
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .navbar.responsive .icon {
    position: absolute;
    right: 0;
    top: 0;
  }
  .navbar.responsive a {
    float: none;
    display: block;
    text-align: left;
  }
  .navbar.responsive .dropdown {
    float: none;
  }
  .navbar.responsive .dropdown-content {
    position: relative;
  }
  .navbar.responsive .dropdown .dropbtn {
    display: flex;
    width: 100%;
    text-align: left;
    
  }
  .column {
    width: 100%;
    height: auto;
  }
}
