.flip-card {
  background-color: transparent;
  width: 300px;
  height: 300px;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.flip-card:hover .flip-card-inner,
.flip-card:focus .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  border-radius: 0.625rem;
}

.flip-card-front {
  background: #132043;
  color: black;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flip-card-back {
  background: #132043;
  color: white;
  transform: rotateY(180deg);
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.image {
  flex: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.image img {
  width: 100%;
  height: auto;
}

.image-caption {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #132043;
  color: white;
  font-weight: bold;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}

ul {
  list-style-type: disc;
  padding-left: 1.25rem;
}

button {
  background-color: #CACFD6;
  padding: 0.5rem;
  border-radius: 1rem;
  cursor: pointer;
  color: black;
}

button a {
  text-decoration: none;
  color: black;
}

button:hover {
  background-color: #b0b3b8;

}
