.dropdown-content {
    position:fixed;
    background-color: #132043;
    width: 100%;
    left: 0;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    display: block;
  }
  
.centered-container {
  display: flex;
  justify-content: center; /* Centers horizontally */
  align-items: center;      /* Centers vertically */
  height: 100vh;            /* Takes the full viewport height */
}

.industries-column {
  display: flex;
  flex-direction: column; /* Stacks links vertically */
  align-items: center;    /* Centers the links horizontally */
  gap: 15px;              /* Adds space between each item */
}

.industries-column a {
  color: white;            /* Adjust text color */
  text-decoration: none;   /* Removes underline */
  padding: 5px 0;
}